import { atom } from "recoil";

export const showCaseStudy = atom<boolean>({
  key: "show_case_study",
  default: false,
});
export const showRequestModal = atom<boolean>({
  key: "show_request_modal",
  default: false,
});
