"use client";

import { Fragment, useMemo, useState } from "react";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import venture_log from "../../assets/images/venture_log.png";
import venture_logo from "../../assets/images/venture_logo.png";
import mobile_logo from "../../assets/images/venture_white_logo.svg";
import { Menu, Transition } from "@headlessui/react";
import { WhiteBtn, WhiteProps } from "../LearnMore";
import { Link } from "react-router-dom";
import { showCaseStudy, showRequestModal } from "../../store";
import { useRecoilState } from "recoil";
interface subItem {
  caption: string;
  url: string;
}
interface ISubMenus {
  title: string;
  menuItems: subItem[];
  keyNum?: number;
  curKey?: number;
  isCurLink?: boolean;
  setIsOpened?: (val: number) => void;
  isDesktopMod?: boolean;
  selected?: string;
  onAfterLink?: () => void;
}
export interface ILinkProps {
  title: string;
  link: string;
  selected: string;
  isCurOpen?: boolean;
  decorationCls?: string;
  goLink?: () => void;
}
export const HeaderBtn = (props: WhiteProps) => {
  const { title, disabled, onAction } = props;
  return (
    <button
      className={`border border-[#2E90F2] font-semibold px-4 py-2 rounded-lg bg-white text-my-gray ${
        disabled && " opacity-50"
      }`}
      onClick={() => {
        if (onAction) {
          onAction(title);
        }
      }}
    >
      {title}
    </button>
  );
};
const DropdownTrigger = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M12.1897 16.2131L18.1997 10.2031L16.7867 8.78809L12.1897 13.3881L7.59369 8.78809L6.17969 10.2021L12.1897 16.2131Z"
        fill="white"
      />
    </svg>
  );
};
export const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [showCase, setShowCase] = useRecoilState(showCaseStudy);
  const [showReqModal, setShowReqModal] = useRecoilState(showRequestModal);

  return (
    <header
      className={`md:mb-5 xl:mb-10 bg-white md:sticky sm:static top-0 z-30`}
    >
      {/** ipad, desktop mode */}
      <nav className="justify-center hidden p-6 xl:justify-between max-xl:hidden xl:flex md_menu_bg xl:px-5">
        <NavLink to={"/"} className={"max-xl:hidden xl:flex items-center"}>
          <img
            src={venture_log}
            alt="logo"
            className="hidden md:block md:w-[130px] "
          />
        </NavLink>
        <div className="flex space-x-8 pe-8">
          <div className="flex space-x-4">
            <h6 className="text-white font-bold w-[260px]">
              Are you an entrepreneur looking to join our community?
            </h6>
            <button
              className="px-4 py-1 font-base bg-white rounded-lg border-1 border-[#2E90F2]"
              onClick={() => {
                setShowCase(true)
                setShowReqModal(true);
              }}
            >
              Learn More
            </button>
          </div>
          <div className="flex space-x-4">
            <h6 className="text-white font-bold w-[260px]">
              Are you an angel investor or VC interested in more information?
            </h6>
            <button
              className="px-4 py-1 font-base bg-white rounded-lg border-1 border-[#2E90F2]"
              onClick={() => {
                setShowCase(false)
                setShowReqModal(true);
              }}
            >
              Learn More
            </button>
          </div>
        </div>
      </nav>
      <nav className="justify-between px-3 py-2 space-x-2 max-md:hidden md:flex xl:hidden md_menu_bg">
        <div className="flex space-x-4">
          <h6 className="text-white font-bold w-[230px] text-sm">
            Are you an entrepreneur looking to join our community?
          </h6>
          <button
            className="px-3 py-1 font-base bg-white rounded-lg border-1 border-[#2E90F2] text-sm"
            onClick={() => {
              setShowCase(true)
              setShowReqModal(true);
            }}
          >
            Learn More
          </button>
        </div>
        <div className="flex space-x-4">
          <h6 className="text-white font-bold w-[230px] text-sm">
            Are you an angel investor or VC interested in more information?
          </h6>
          <button
            className="px-3 py-1 font-base bg-white rounded-lg border-1 border-[#2E90F2] text-sm"
            onClick={() => {
              setShowCase(false)
              setShowReqModal(true);
            }}
          >
            Learn More
          </button>
        </div>
      </nav>
      {/** ipad, desktop mode */}
      {/** mobile response mode */}
      <nav
        className={`hidden max-md:block max-md:sticky max-md:top-0 max-md:z-60 ${
          isOpen ? "md_menu_bg" : ""
        } `}
      >
        {isOpen ? (
          <div className="pt-5">
            <div className="flex justify-between px-5">
              <div>
                <img src={mobile_logo} alt="" className="w-[170px]" />
              </div>
            </div>
          </div>
        ) : (
          <div className="px-5 py-3 h-[82px] w-full md_menu_bg flex justify-start">
            <NavLink to={"/"} className="flex items-end justify-center pt-4">
              <img src={mobile_logo} alt="mark" className="w-[170px]" />
            </NavLink>
          </div>
        )}
        <div className="hidden mt-6 gap-y-4">
          <div className="grid gap-y-2">
            <h6 className=" font-bold px-4 text-[#383231] text-center">
              Are you an entrepreneur looking to join our community?
            </h6>
            <div>
              <button
                className="mx-auto block px-4 py-1 font-base bg-[#2E90F2] text-white rounded-lg border-4 border-[#2E90F2]"
                onClick={() => {
                  setShowCase(true)
                  setShowReqModal(true);
                }}
              >
                Learn More
              </button>
            </div>
          </div>
          <div className="grid gap-y-2">
            <h6 className="text-[#383231] font-bold px-4 text-center">
              Are you an angel investor or VC interested in more information?
            </h6>
            <div>
              <button
                className="mx-auto block px-4 py-1 font-base bg-[#2E90F2] text-white rounded-lg border-4 border-[#2E90F2]"
                onClick={() => {
                  setShowCase(false)
                  setShowReqModal(true);
                }}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
