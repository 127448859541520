import { Suspense, lazy } from "react";
import "./App.css";
import ReactLoading from "react-loading";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "./components/layouts/Layout";
import "./assets/css/main.css";
import ScrollToTop from "./components/ScrollToTop";

const ARSOME_Ventures = lazy(() =>
  import("./pages/ARSOME_Ventures").then((module) => ({
    default: module.ARSOME_Ventures,
  })),
);
const LoadStatus = () => {
  return (
    <div className="w-full py-4 text-center">
      <ReactLoading
        type="spinningBubbles"
        className="mx-auto my-auto"
        height={"70px"}
        width={"70px"}
        color="#666666"
      />
    </div>
  );
};
function App() {
  return (
    <>
      <Suspense fallback={<LoadStatus />}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route index path="" element={<ARSOME_Ventures />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;
